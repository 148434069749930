import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FilterDropdownComponent } from '../filter-dropdown/filter-dropdown.component';
import { DropdownOption } from '@zonar-ui/searchable-dropdown';
import { STATUSES, ZpxApiPatchParamNames } from '@src/app/models/zpx-api.model';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-passholder-status-filter',
  standalone: true,
  imports: [CommonModule, FilterDropdownComponent],
  templateUrl: './passholder-status-filter.component.html',
  styleUrls: ['./passholder-status-filter.component.scss']
})
export class PassholderStatusFilterComponent implements OnInit {
  constructor() {}

  options: DropdownOption[] = [
    { title: STATUSES.ACTIVE, value: '1' },
    { title: STATUSES.INACTIVE, value: '0' }
  ];

  formControlName = ZpxApiPatchParamNames.ACTIVE;
  label = 'Status';

  @Input() formGroup: FormGroup;
  @Input() defaultOption: string = null;

  ngOnInit(): void {
    this.formGroup.addControl(this.formControlName, new FormControl());

    if (this.defaultOption) {
      this.formGroup.controls[this.formControlName].setValue(
        this.defaultOption,
        {
          emitEvent: false,
          onlySelf: true
        }
      );
    }
  }
}
