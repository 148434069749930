import { Injectable } from '@angular/core';

import { GetDivisionsService } from '../get-divisions/get-divisions.service';
import { filter } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { DivisionPickerModalComponent } from '@src/app/components/division-picker-modal/division-picker-modal.component';

@Injectable({
  providedIn: 'root'
})
export class DivisionModalService {
  constructor(
    private divisionService: GetDivisionsService,
    private dialog: MatDialog
  ) {}

  openDivisionModal() {
    this.divisionService
      .getDivisions()
      .pipe(filter((divs) => divs.length > 1))
      .subscribe((divs) => {
        this.dialog.open(DivisionPickerModalComponent, {
          data: {
            divisions: divs
          },
          disableClose: true
        });
      });
  }
}
