import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { FormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import {
  MAT_FORM_FIELD_DEFAULT_OPTIONS,
  MatFormFieldModule
} from '@angular/material/form-field';

@Component({
  selector: 'app-zpx-input',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    MatInputModule,
    MatIconModule,
    MatFormFieldModule
  ],
  templateUrl: './zpx-input.component.html',
  styleUrls: ['./zpx-input.component.scss'],
  providers: [
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { appearance: 'outline' }
    }
  ]
})
export class ZpxInputComponent {
  constructor() {}

  // @Input() value: string = 'Default Value';
  @Input() label: string = 'Default Label';
  @Input() value: string = 'Default Value';
  @Input() zpxInputName: string = 'Default Input Name';
  @Input() disabled: boolean = false;
  @Output() inputKeyPress: EventEmitter<any> = new EventEmitter();

  _keyPress() {
    this.inputKeyPress.emit({
      formName: this.zpxInputName,
      value: this.value
    });
  }
}
