<div id="passholder-filter-bar">
  <app-passholder-type-filter
    [formGroup]="formGroup"
    [defaultOption]="( getTypeDefaultOption() | async )">
  </app-passholder-type-filter>

  <app-passholder-group-filter
    [formGroup]="formGroup"
    [defaultOption]="( getGroupDefaultOption() | async )">
  </app-passholder-group-filter>

  <app-passholder-status-filter
    [formGroup]="formGroup"
    [defaultOption]="getStatusDefaultOption()">
  </app-passholder-status-filter>

  <app-zpx-input
    *ngFor="let name of formControlInputNames"
    [zpxInputName]="name"
    [label]="formControlInputs[name].label"
    [value]="formControlInputs[name].value"
    [disabled]="name === 'unique_id'"
    (inputKeyPress)="inputKeyPress($event)">
  </app-zpx-input>

  <app-zpx-input
    *ngFor="let cc of (getCustomColumnInputs() | async )"
    [zpxInputName]="cc.name"
    [label]="cc.name"
    [value]="passholder[cc.name]"
    (inputKeyPress)="inputKeyPress($event)">
  </app-zpx-input>
</div>
