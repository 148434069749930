import { Component, Input, EventEmitter, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  ZonarUiSearchableDropdownModule,
  DropdownOption
} from '@zonar-ui/searchable-dropdown';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-filter-dropdown',
  standalone: true,
  imports: [CommonModule, ZonarUiSearchableDropdownModule],
  templateUrl: './filter-dropdown.component.html',
  styleUrls: ['./filter-dropdown.component.scss']
})
export class FilterDropdownComponent {
  constructor() {}

  @Input() options: DropdownOption[] = [];
  @Input() label = 'Default Label';
  @Input() isMultiple = false;
  @Input() z_formGroup: FormGroup;
  @Input() selectOptionsControlName;
  @Input() classIdentifer = 'zpx-filter-dropdown';
}
