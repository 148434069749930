import { Component, Input, Output, EventEmitter } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BehaviorSubject, Observable, of } from 'rxjs';
import {
  ZonarUITableDataSource,
  ZonarUITableModel,
  ZonarUITableModule
} from '@zonar-ui/table';
import { GetEnvironmentService } from '@src/app/services/get-environment/get-environment.service';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'app-zpx-table',
  standalone: true,
  imports: [CommonModule, ZonarUITableModule, MatIconModule],
  templateUrl: './zpx-table.component.html',
  styleUrls: ['./zpx-table.component.scss']
})
export class ZpxTableComponent {
  constructor(private getEnvService: GetEnvironmentService) {}

  @Output() rowClick: EventEmitter<any> = new EventEmitter();
  @Input() title: string = 'My Table';
  @Input() columns: BehaviorSubject<ZonarUITableModel[]> = new BehaviorSubject(
    []
  );
  @Input() dataSource: ZonarUITableDataSource;
  @Input() initialPageSize: number =
    this.getEnvService.getEnvironmentProperty('paginationSettings')['pageSize'];

  click(event) {
    this.rowClick.emit(event);
  }
}
