import { DropdownOption } from '@zonar-ui/searchable-dropdown';

export const isLocalDevEnv = () => {
  // in order for unit tests (ran from localhost:9876) to pass, they need to be excluded from 'local'
  return (
    window?.location?.origin?.includes('localhost:4200') ||
    window?.location?.origin?.includes('local.dev')
  );
};

// title and string args should be two keys from the objects of the passed in array
export const makeDropdownOptions = (
  arr: any[],
  title: string,
  value: string
): DropdownOption[] => {
  return arr.map((item) => {
    return {
      title: item[title],
      value: item[value]
    };
  });
};
