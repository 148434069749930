import { Injectable } from '@angular/core';
import { environment } from '@src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GetEnvironmentService {
  constructor() {}

  getEnvironment() {
    return environment;
  }

  getEnvironmentProperty(key) {
    return environment[key];
  }
}
