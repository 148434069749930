import {
  Component,
  ViewEncapsulation,
  Input,
  OnInit,
  OnDestroy,
  Output,
  EventEmitter
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormBuilder } from '@angular/forms';
import { ZpxInputComponent } from '../zpx-input/zpx-input.component';
import { FormsModule } from '@angular/forms';
import {
  CustomTypeColumn,
  PassholderForTable,
  PassholderPatchBody,
  STATUSES,
  ZpxApiPatchParamNames
} from '@src/app/models/zpx-api.model';
import { filter, map, takeUntil } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { PassholderStatusFilterComponent } from '../passholder-status-filter/passholder-status-filter.component';
import { PassholderTypeFilterComponent } from '../passholder-type-filter/passholder-type-filter.component';
import { PassholderGroupFilterComponent } from '../passholder-group-filter/passholder-group-filter.component';
import { AppService } from '@src/app/app.service';
import { HttpRequest } from '@angular/common/http';
@Component({
  selector: 'app-passholder-filter-bar',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ZpxInputComponent,
    PassholderStatusFilterComponent,
    PassholderTypeFilterComponent,
    PassholderGroupFilterComponent
  ],
  templateUrl: './passholder-filter-bar.component.html',
  styleUrls: ['./passholder-filter-bar.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PassholderFilterBarComponent implements OnInit, OnDestroy {
  constructor(
    private fb: FormBuilder,
    private appService: AppService
  ) {}

  private onDestroy$ = new Subject<void>();

  @Output() sendPatchBody: EventEmitter<PassholderPatchBody> = new EventEmitter(
    null
  );
  @Input() passholder: PassholderForTable = null;

  patchBody: PassholderPatchBody = null;
  customColumns: CustomTypeColumn[] = null;

  formGroup = this.fb.group({});

  formControlDropdownNames = [
    ZpxApiPatchParamNames.TYPE,
    ZpxApiPatchParamNames.GROUP,
    ZpxApiPatchParamNames.ACTIVE
  ];
  formControlInputNames = [
    ZpxApiPatchParamNames.PASS_NUMBER,
    ZpxApiPatchParamNames.LAST_NAME,
    ZpxApiPatchParamNames.FIRST_NAME,
    'unique_id'
  ];

  formControlInputs = null;

  getCustomColumnInputs() {
    return this.appService.customColumns$;
  }

  getStatusDefaultOption() {
    return this.passholder.active ? '1' : '0';
  }

  getTypeDefaultOption(): Observable<string> {
    return this.appService.passholderTypeId$;
  }

  getGroupDefaultOption(): Observable<string> {
    return this.appService.groups$.pipe(
      map((groups) => {
        return groups.find((group) => group.name === this.passholder.group_name)
          .id;
      })
    );
  }

  setStandardFormInputs() {
    this.formControlInputs = {
      [ZpxApiPatchParamNames.PASS_NUMBER]: {
        label: 'Card No.',
        value: this.passholder.card_number
      },
      [ZpxApiPatchParamNames.LAST_NAME]: {
        label: 'Last Name',
        value: this.passholder.last_name
      },
      [ZpxApiPatchParamNames.FIRST_NAME]: {
        label: 'First Name',
        value: this.passholder.first_name
      },
      unique_id: {
        label: 'Unique Id',
        value: this.passholder.unique_id
      }
    };
  }

  getPatchRequestBody(
    formName: string,
    formValue: string
  ): PassholderPatchBody {
    const updatedValue =
      formName === ZpxApiPatchParamNames.ACTIVE
        ? Boolean(parseInt(formValue))
        : formValue;

    this.patchBody = {
      ...this.patchBody,
      [formName]: updatedValue
    };

    return this.patchBody;
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  ngOnInit() {
    this.getCustomColumnInputs()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((columns) => {
        this.customColumns = columns;
      });
    this.setStandardFormInputs();
    this.formGroup.valueChanges
      .pipe(
        filter((changes) => {
          return this.formControlDropdownNames.some((name) => changes[name]);
        }),
        takeUntil(this.onDestroy$)
      )
      .subscribe((changes) => {
        let httpPatchRequestBody: PassholderPatchBody;

        this.formControlDropdownNames.forEach((name) => {
          httpPatchRequestBody = this.getPatchRequestBody(name, changes[name]);
        });

        this.sendPatchBody.emit(httpPatchRequestBody);
      });
  }

  getCustomColumnsForPatchBody(formName: string, formValue: string) {
    if (!this.formControlInputNames.includes(formName)) {
      const id = this.customColumns.find((cc) => cc.name === formName).id;

      let updatedCC = [];

      const previouslySetCustomColumns =
        this.patchBody.custom_type_values_update;

      if (previouslySetCustomColumns?.length) {
        updatedCC = [...previouslySetCustomColumns];
        updatedCC.push({ column_id: id, value: formValue });
      } else {
        updatedCC.push({ column_id: id, value: formValue });
      }

      const filteredCC = [];

      this.customColumns.forEach((cc) => {
        const columnVals = updatedCC.filter(
          (updatedCC) => cc.id === updatedCC?.column_id
        );
        if (columnVals[columnVals.length - 1]) {
          filteredCC.push(columnVals[columnVals.length - 1]);
        }
      });

      this.patchBody = {
        ...this.patchBody,
        custom_type_values_update: filteredCC
      };
    }
    return this.patchBody;
  }

  inputKeyPress(changes: { formName: string; value: string }) {
    let httpPatchRequestBody: PassholderPatchBody = this.getPatchRequestBody(
      changes.formName,
      changes.value
    );

    httpPatchRequestBody = this.getCustomColumnsForPatchBody(
      changes.formName,
      changes.value
    );

    this.sendPatchBody.emit(httpPatchRequestBody);
  }
}
