export const environment = {
  paginationSettings: {
    pageSize: 10
  },
  name: 'production',
  region: 'NA',
  production: true,
  appUrl: 'https://zpass.production.zonarsystems.net',

  apiBase: {
    url: 'https://api.zonarsystems.net/zpassx-api'
  },

  coreEntityApiBase: {
    url: 'https://api.zonarsystems.net/core/v1'
  },
  // TODO: Setup your application's authentication variables: see
  // https://gitlab.com/ZonarSystems/tools/pattern-library/-/tree/master/projects/zonar-ui-auth
  // for instructions on how to do that.
  authEnv: 'production',

  auth: {
    clientID: 'nyHliqv0CIXrb19IlzaQwnzIUKfUrHZy',
    domain: 'zonar-login.auth0.com',
    audience: 'https://api.zonarsystems.net',
    // These come from the Core APIs as your application is built.
    applicationId: 'a215e38f-dfec-41d1-9e85-f58f2980ae72',
    defaultZonarRole: 'b8238523-4266-4788-a7d4-b27c37e8a474',
    useRefreshTokens: true
  },

  pendo: {
    url: 'https://cdn.pendo.io/agent/static/'
  },

  googleTagManager: {
    id: 'GTM-PM9R42P',
    auth: '',
    environment: ''
  },

  //TODO: Setup your application's datadog app (see empty box readme)
  datadog: {
    applicationId: '57878a2e-49d1-4e54-8a5d-989a78f8e8c9',
    clientToken: 'pub3ae6b4efaba3048aa70d308f5c3df1b5',
    site: 'us5.datadoghq.com',
    tagEnvironment: 'prod'
  },

  i18n: {
    supportedLanguages: [
      'en',
      'en-US',
      'de-DE',
      'en-GB',
      'it',
      'fr-FR',
      'de',
      'es-ES'
    ],
    defaultLanguage: 'en-US'
  }
};
