import { Injectable } from '@angular/core';
import { AppService } from '@src/app/app.service';
import {
  CustomTypeColumn,
  PASSHOLDER_COMMON_COLUMNS,
  PASSHOLDER_TYPES,
  ZpxApiFilterNames,
  ZpxApiPassholderGetReportBody
} from '@src/app/models/zpx-api.model';
import { ZpxApiService } from '@src/app/services/zpx-api-service/zpx-api.service';
import { makeDropdownOptions } from '@src/app/shared/utilities/utilities';

import {
  DropdownOptionsObject,
  FILTER_TYPE,
  SearchableDropdownModel
} from '@zonar-ui/filter';

import { BehaviorSubject, merge, Observable, of } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import {
  CachedPassholderTypesFiltersModel,
  COMMON_COLUMN_TYPES,
  CustomColumnSearchableDropdown,
  PassholderTypeFiltersCache,
  TablePassholdersFilterGroupValue
} from './models/table-passholders-filter-bar.model';

import _ from 'lodash-es';

@Injectable({
  providedIn: 'root'
})
export class TablePassholdersFilterBarService {
  constructor(
    private zpxApiService: ZpxApiService,
    private appService: AppService
  ) {}

  displayedOptions = {
    divisionNameOptions$: new BehaviorSubject([] as DropdownOptionsObject[]),
    lastName$: new BehaviorSubject([] as DropdownOptionsObject[]),
    firstName$: new BehaviorSubject([] as DropdownOptionsObject[]),
    passNumber$: new BehaviorSubject([] as DropdownOptionsObject[]),
    uniqueId$: new BehaviorSubject([] as DropdownOptionsObject[]),
    group$: new BehaviorSubject([] as DropdownOptionsObject[]),
    status$: new BehaviorSubject([
      { title: 'Active', value: 'Active' },
      { title: 'Inactive', value: 'Inactive' }
    ] as DropdownOptionsObject[])
  };

  // hack of making the "of" rxjs method a class function for easier spying in testing
  public of = of;

  // This is used to maintain the state of the user-selected filter options
  filterBody$: BehaviorSubject<ZpxApiPassholderGetReportBody> =
    new BehaviorSubject(null);

  private cachedPassholderTypes: CachedPassholderTypesFiltersModel = {
    student: new PassholderTypeFiltersCache(PASSHOLDER_TYPES.STUDENT),
    driver: new PassholderTypeFiltersCache(PASSHOLDER_TYPES.DRIVER),
    aide: new PassholderTypeFiltersCache(PASSHOLDER_TYPES.AIDE)
  };

  private commonDropdownProps = {
    isMultiple: true,
    blueCheckmarks: true,
    inputParams: []
  };

  public getDivisonModel(divisionId: string): SearchableDropdownModel {
    return {
      type: FILTER_TYPE.SEARCHABLE_DROPDOWN,
      options: {
        ...this.commonDropdownProps,
        label: 'Division Name',
        data: this.displayedOptions.divisionNameOptions$,
        fgControlName: ZpxApiFilterNames.DIVISION_ID,
        valueType: 'string',
        enableAllOptions: false,
        paramName: 'division_id',
        defaultValue: divisionId,
        ...this.commonDropdownProps,
        isMultiple: false
      }
    };
  }

  public getCustomColumnFilters$(
    passholderType: PASSHOLDER_TYPES
  ): Observable<CustomColumnSearchableDropdown[]> {
    const passholderTypeCustomFilters =
      this.cachedPassholderTypes[passholderType].getCustomColumnFilters();

    // commented out because still buggy when switching tabs, we can revisit this later as tech debt
    // if (passholderTypeCustomFilters) {
    //   return this.of(passholderTypeCustomFilters);
    // }
    return this.appService.passholderTypes$.pipe(
      filter((passholderTypes) => passholderTypes !== null),
      map(
        (passholderTypes) =>
          passholderTypes.find((pt) => pt.name == passholderType).id
      ),
      switchMap((passholderTypeId: string) =>
        this.appService.customColumns$.pipe(
          filter((customColumns: CustomTypeColumn[]) => customColumns !== null),
          map((customColumns) => {
            if (!customColumns.length) {
              this.cachedPassholderTypes[passholderType].customColumns.filters =
                [];
              return [];
            }
            const customColumnFilters = customColumns.map((cc) => {
              this.displayedOptions[cc.id] = new BehaviorSubject(
                [] as DropdownOptionsObject[]
              );
              const custColFilter: CustomColumnSearchableDropdown = {
                type: FILTER_TYPE.SEARCHABLE_DROPDOWN,
                customColumnId: cc.id,
                options: {
                  label: cc.name,
                  data: this.displayedOptions[cc.id],
                  fgControlName: `custCol_${cc.id}`,
                  valueType: 'string',
                  enableAllOptions: false,
                  paramName: cc.id,
                  ...this.commonDropdownProps
                }
              };
              return custColFilter;
            });
            this.cachedPassholderTypes[passholderType].customColumns.filters =
              customColumnFilters;
            return customColumnFilters;
          })
        )
      )
    );
  }

  public getCommonColumnsFilters(): SearchableDropdownModel[] {
    return [
      {
        type: FILTER_TYPE.SEARCHABLE_DROPDOWN,
        options: {
          ...this.commonDropdownProps,
          label: 'Division',
          data: this.displayedOptions.divisionNameOptions$,
          fgControlName: ZpxApiFilterNames.DIVISION_ID,
          valueType: 'string',
          enableAllOptions: false,
          paramName: 'division_id',
          ...this.commonDropdownProps,
          isMultiple: false
        }
      },
      {
        type: FILTER_TYPE.SEARCHABLE_DROPDOWN,
        options: {
          label: 'Status',
          data: this.displayedOptions.status$,
          fgControlName: 'active',
          valueType: 'boolean',
          enableAllOptions: false,
          paramName: 'status',
          defaultValue: 'Active',
          ...this.commonDropdownProps
        }
      },
      {
        type: FILTER_TYPE.SEARCHABLE_DROPDOWN,
        options: {
          label: 'Last Name',
          data: this.displayedOptions.lastName$,
          fgControlName: 'last_name',
          valueType: 'string',
          enableAllOptions: false,
          paramName: 'lastName',
          ...this.commonDropdownProps
        }
      },
      {
        type: FILTER_TYPE.SEARCHABLE_DROPDOWN,
        options: {
          label: 'First Name',
          data: this.displayedOptions.firstName$,
          fgControlName: 'first_name',
          valueType: 'string',
          enableAllOptions: false,
          paramName: 'firstName',
          ...this.commonDropdownProps
        }
      },
      {
        type: FILTER_TYPE.SEARCHABLE_DROPDOWN,
        options: {
          label: 'Card No.',
          data: this.displayedOptions.passNumber$,
          fgControlName: 'pass_number',
          valueType: 'string',
          enableAllOptions: false,
          paramName: 'passNumber',
          ...this.commonDropdownProps
        }
      },
      {
        type: FILTER_TYPE.SEARCHABLE_DROPDOWN,
        options: {
          label: 'Unique Id',
          data: this.displayedOptions.uniqueId$,
          fgControlName: 'exsid',
          valueType: 'string',
          enableAllOptions: false,
          paramName: 'uniqueId',
          ...this.commonDropdownProps
        }
      },
      {
        type: FILTER_TYPE.SEARCHABLE_DROPDOWN,
        options: {
          label: 'Group',
          data: this.displayedOptions.group$,
          fgControlName: 'group',
          valueType: 'string',
          enableAllOptions: false,
          paramName: 'groupName',
          ...this.commonDropdownProps
        }
      }
    ];
  }

  parseUserSelectedFilters(
    selectedFilterObj: TablePassholdersFilterGroupValue
  ) {
    const filterableKeys = [
      'first_name',
      'last_name',
      'exsid',
      'group',
      'pass_number'
    ];
    const formattedBody = { ..._.pick(selectedFilterObj, filterableKeys) };
    const specialMappingRequired = _.omit(selectedFilterObj, filterableKeys);
    // Special treatment for atypical filter keys
    _.forEach(specialMappingRequired, (val, key) => {
      if (key == 'active') {
        const vals = [];
        if (val.includes('Active')) {
          vals.push(true);
        }
        if (val.includes('Inactive')) {
          vals.push(false);
        }
        formattedBody['active'] = vals;
      }
      if (key.includes('custCol_')) {
        // Ensure that custom_columns key exists
        formattedBody.custom_columns = formattedBody.custom_columns || {};
        const custColId = key.split('custCol_')[1];
        formattedBody.custom_columns[custColId] = val;
      }
    });
    this.filterBody$.next(formattedBody);
  }

  private _convertToDropdownObjects(arr: string[]): DropdownOptionsObject[] {
    return arr.map((x) => ({ title: x, value: x }));
  }

  public setCommonColumnFiltersOptions(passholderType: PASSHOLDER_TYPES) {
    const fNameObs$ = this.zpxApiService
      .getPassholderCommonColumnValues(PASSHOLDER_COMMON_COLUMNS.FIRST_NAMES)
      .pipe(
        map((resp) => {
          const firstNameOptions = this._convertToDropdownObjects(
            resp.first_names
          );
          this.cachedPassholderTypes[
            passholderType
          ].commonColumns.dropDownOptions[COMMON_COLUMN_TYPES.FIRST_NAMES] =
            firstNameOptions;
          this.displayedOptions.firstName$.next(firstNameOptions);
        })
      );
    const lNameObs$ = this.zpxApiService
      .getPassholderCommonColumnValues(PASSHOLDER_COMMON_COLUMNS.LAST_NAMES)
      .pipe(
        map((resp) => {
          const lastNameOptions = this._convertToDropdownObjects(
            resp.last_names
          );
          this.cachedPassholderTypes[
            passholderType
          ].commonColumns.dropDownOptions[COMMON_COLUMN_TYPES.LAST_NAMES] =
            lastNameOptions;
          this.displayedOptions.lastName$.next(lastNameOptions);
        })
      );
    const uniqueIdObs$ = this.zpxApiService
      .getPassholderCommonColumnValues(PASSHOLDER_COMMON_COLUMNS.EXSIDS)
      .pipe(
        map((resp) => {
          const exsidOptions = this._convertToDropdownObjects(resp.exsids);
          this.cachedPassholderTypes[
            passholderType
          ].commonColumns.dropDownOptions[COMMON_COLUMN_TYPES.UNIQUE_IDS] =
            exsidOptions;
          this.displayedOptions.uniqueId$.next(exsidOptions);
        })
      );
    const passNumbersObs$ = this.zpxApiService
      .getPassholderCommonColumnValues(PASSHOLDER_COMMON_COLUMNS.NUMBERS)
      .pipe(
        map((resp) => {
          const numbersOptions = this._convertToDropdownObjects(
            resp.pass_numbers
          );
          this.cachedPassholderTypes[
            passholderType
          ].commonColumns.dropDownOptions[COMMON_COLUMN_TYPES.NUMBERS] =
            numbersOptions;
          this.displayedOptions.passNumber$.next(numbersOptions);
        })
      );
    // TODO This probably only needs to be called once, since groups span across passholder types
    const groupsObs$ = this.appService.groups$.pipe(
      filter((groups) => groups !== null),
      map((resp) => {
        const groupOptions = resp.map((group) => ({
          title: group.name,
          value: group.id
        }));
        this.cachedPassholderTypes[
          passholderType
        ].commonColumns.dropDownOptions[COMMON_COLUMN_TYPES.GROUPS] =
          groupOptions;
        this.displayedOptions.group$.next(groupOptions);
      })
    );

    const divisionObs$ = this.appService.divisions$.pipe(
      filter((divs) => divs !== null),
      map((divs) => {
        const divOptions = makeDropdownOptions(divs, 'name', 'id');
        this.cachedPassholderTypes[passholderType][
          COMMON_COLUMN_TYPES.DIVISIONS
        ] = divOptions;
        this.displayedOptions.divisionNameOptions$.next(divOptions);
      })
    );
    // This is used for caching, but also to handle instances where one of the requests to populate the dropdown fails for some reason
    const colFiltersMap = {
      firstNames: {
        obs: fNameObs$,
        options: this.displayedOptions.firstName$
      },
      lastNames: {
        obs: lNameObs$,
        options: this.displayedOptions.lastName$
      },
      uniqueIds: {
        obs: uniqueIdObs$,
        options: this.displayedOptions.uniqueId$
      },
      numbers: {
        obs: passNumbersObs$,
        options: this.displayedOptions.passNumber$
      },
      groups: {
        obs: groupsObs$,
        options: this.displayedOptions.group$
      },
      divisons: {
        obs: divisionObs$,
        options: this.displayedOptions.divisionNameOptions$
      }
    };
    const getCommonColOptionsObss$ = [];
    const passholderTypeCommonColOptions =
      this.cachedPassholderTypes[passholderType].getCommonColumnsOptions();

    if (!Object.keys(passholderTypeCommonColOptions).length) {
      getCommonColOptionsObss$.push(
        fNameObs$,
        lNameObs$,
        uniqueIdObs$,
        passNumbersObs$,
        groupsObs$,
        divisionObs$
      );
    } else {
      Object.keys(colFiltersMap).forEach((k: COMMON_COLUMN_TYPES) => {
        if (
          passholderTypeCommonColOptions[k] === undefined ||
          passholderTypeCommonColOptions[k].length === 0
        ) {
          getCommonColOptionsObss$.push(colFiltersMap[k].obs);
        } else {
          colFiltersMap[k].options.next(
            this.cachedPassholderTypes[passholderType].commonColumns
              .dropDownOptions[k]
          );
        }
      });
    }

    // Run requests in parallel
    merge(...getCommonColOptionsObss$).subscribe();
  }

  setCustomColumnsFiltersOptions(
    passholderType: PASSHOLDER_TYPES,
    customColumnFilters: CustomColumnSearchableDropdown[]
  ) {
    const customColOptionsObs$ = [];
    const cachedCustomColumnOptions =
      this.cachedPassholderTypes[passholderType].getCustomColumnOptions();
    customColumnFilters.forEach((ccf) => {
      if (ccf.customColumnId in cachedCustomColumnOptions) {
        this.displayedOptions[ccf.customColumnId].next(
          cachedCustomColumnOptions[ccf.customColumnId]
        );
      } else {
        customColOptionsObs$.push(
          this.zpxApiService.getCustomColumnValues(ccf.customColumnId).pipe(
            map((resp) => {
              const customColumnValues = this._convertToDropdownObjects(
                resp.values
              );
              this.cachedPassholderTypes[
                passholderType
              ].customColumns.dropDownOptions[ccf.customColumnId] =
                customColumnValues;
              this.displayedOptions[ccf.customColumnId].next(
                customColumnValues
              );
            })
          )
        );
      }
    });
    // Run requests in parallel
    merge(...customColOptionsObs$).subscribe();
  }
}
