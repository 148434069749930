import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, throwError } from 'rxjs';
import { CollectionViewer } from '@angular/cdk/collections';
import {
  ZonarUITableDataSource,
  ZonarUITableModel,
  ZonarUITableCellType
} from '@zonar-ui/table';
import { Params } from '@angular/router';
import { ZpxApiService } from '@src/app/services/zpx-api-service/zpx-api.service';
import {
  PassholderForTable,
  PASSHOLDER_COLUMN_HEADERS,
  STATUSES,
  ZpxApiPassholderParams,
  Passholder,
  PassholdersReportHttpResponseBody,
  CustomTypeColumn
} from '@src/app/models/zpx-api.model';
import { GetEnvironmentService } from '@src/app/services/get-environment/get-environment.service';
import _ from 'lodash-es';
import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import * as dayjs from 'dayjs';
import { AppService } from '@src/app/app.service';
import { GetDivisionsService } from '@src/app/services/get-divisions/get-divisions.service';
import { TablePassholdersFilterBarService } from '../table-passholders-filter-bar/table-passholders-filter-bar.service';

@Injectable()
export class PassholderTableDataSource implements ZonarUITableDataSource {
  constructor(
    private zpxApiService: ZpxApiService,
    private getEnvService: GetEnvironmentService,
    private appService: AppService,
    private divisionsService: GetDivisionsService,
    private filterBarService: TablePassholdersFilterBarService
  ) {}

  private tableLoading = new BehaviorSubject<boolean>(false);
  private errorMessage = new BehaviorSubject<string>('');
  private totalResults = new BehaviorSubject<number>(0);
  private tableDataSubject$ = new BehaviorSubject<any[]>([]);
  private paginationParamsSubject = new BehaviorSubject<any>({});
  customColumnsForTable$ = new BehaviorSubject<ZonarUITableModel[]>([]);
  paginationParams$ = this.paginationParamsSubject.asObservable();
  loading$ = this.tableLoading.asObservable();
  total$ = this.totalResults.asObservable();
  errorMsg$ = this.errorMessage.asObservable();
  data: any[] = [];
  defaultPagination = true;

  private standardColumns: ZonarUITableModel[] = [
    {
      columnDef: 'checkmark',
      header: null,
      sortable: false,
      type: ZonarUITableCellType.Checkbox,
      cellType: ZonarUITableCellType.Checkbox,
      checkboxDisabled: () => false,
      cell: (p) => p
    },
    {
      columnDef: 'card_number',
      header: PASSHOLDER_COLUMN_HEADERS.CARD_NUMBER,
      sortable: false,
      cellType: ZonarUITableCellType.Text,
      cell: (p: PassholderForTable) => p.card_number
    },
    {
      columnDef: 'card_status',
      header: PASSHOLDER_COLUMN_HEADERS.CARD_STATUS,
      sortable: false,
      cellType: ZonarUITableCellType.Text,
      cell: (p: PassholderForTable) => p.card_status
    },
    {
      columnDef: 'last_name',
      header: PASSHOLDER_COLUMN_HEADERS.LAST_NAME,
      sortable: false,
      cellType: ZonarUITableCellType.Text,
      cell: (p: PassholderForTable) => p.last_name
    },
    {
      columnDef: 'first_name',
      header: PASSHOLDER_COLUMN_HEADERS.FIRST_NAME,
      sortable: false,
      cellType: ZonarUITableCellType.Text,
      cell: (p: PassholderForTable) => p.first_name
    },
    {
      columnDef: 'unique_id',
      header: PASSHOLDER_COLUMN_HEADERS.UNIQUE_ID,
      sortable: false,
      cellType: ZonarUITableCellType.Text,
      cell: (p: PassholderForTable) => p.unique_id
    },
    {
      columnDef: 'group_name',
      header: PASSHOLDER_COLUMN_HEADERS.GROUP_NAME,
      sortable: false,
      cellType: ZonarUITableCellType.Text,
      cell: (p: PassholderForTable) => p.group_name
    },
    {
      columnDef: 'card_count',
      header: PASSHOLDER_COLUMN_HEADERS.CARD_COUNT,
      sortable: false,
      cellType: ZonarUITableCellType.Text,
      cell: (p: PassholderForTable) => p.card_count.toString()
    },
    {
      columnDef: 'last_updated',
      header: PASSHOLDER_COLUMN_HEADERS.LAST_UPDATED,
      sortable: false,
      type: ZonarUITableCellType.Text,
      cellType: ZonarUITableCellType.Icon,
      cell: (p: PassholderForTable) => {
        const dateString = this.setDateString(p.last_updated_ts);
        return dateString;
      }
    }
  ];

  pageSize =
    this.getEnvService.getEnvironmentProperty('paginationSettings')['pageSize'];

  loadData(params?: Params): void {
    this.paginationParamsSubject.next(params);
  }

  getStandardColumns(): ZonarUITableModel[] {
    return this.standardColumns;
  }

  setDateString(date: Date): string {
    const dateString = dayjs(date.toDateString()).format('YYYY/MM/DD');
    return dateString;
  }

  getMostRecentPass(
    passes: { number: string; active: boolean; insert_ts: Date }[]
  ): { number: string; active: boolean; insert_ts: Date } {
    return passes
      .slice()
      .sort((a, b) => b.insert_ts.getTime() - a.insert_ts.getTime())[0];
  }

  getPassData(passholders: Passholder[]): any[] {
    const passesForTable = [];
    passholders.forEach((passholder) => {
      const custom_columns = passholder?.custom_columns;
      const placeholderPass = {
        number: null,
        active: null
      };
      let card_count = 0;
      let mostRecentPass = null;
      let passes = [];
      if (passholder.passes) {
        passes = Object.keys(passholder.passes).map((key) => {
          return {
            ...passholder.passes[key],
            insert_ts: new Date(passholder.passes[key].insert_ts)
          };
        });
        card_count = passes.length;
        mostRecentPass = this.getMostRecentPass(passes);
      } else {
        mostRecentPass = placeholderPass;
      }

      let passForTable = {
        card_number: mostRecentPass.number,
        card_status: passholder.active ? STATUSES.ACTIVE : STATUSES.INACTIVE,
        last_name: passholder.last_name,
        first_name: passholder.first_name,
        unique_id: passholder.exsid,
        group_name: passholder.group_name,
        card_count,
        other_cards: passes,
        last_updated_ts: new Date(passholder.last_updated_ts),
        active: passholder.active,
        zpx_id: passholder.id
      };

      if (custom_columns) {
        Object.keys(custom_columns)
          .map((key) => {
            return {
              ...custom_columns[key]
            };
          })
          .sort((a, b) => a.sequence - b.sequence)
          .forEach((c) => {
            passForTable = {
              ...passForTable,
              [c.name]: c.value
            };
          });
      }

      passesForTable.push(passForTable);
    });
    return passesForTable;
  }

  getEditColumn(): ZonarUITableModel {
    return {
      columnDef: 'edit',
      headerIcon: 'edit',
      header: null,
      sortable: false,
      type: ZonarUITableCellType.Icon,
      cellType: ZonarUITableCellType.Icon,
      cell: () => 'edit'
    };
  }

  getCustomColumnsForTable(customTypeColumns: any[]): ZonarUITableModel[] {
    if (customTypeColumns?.length) {
      return customTypeColumns.map((c) => {
        return {
          columnDef: c.name,
          header: c.name,
          sortable: false,
          cellType: ZonarUITableCellType.Text,
          cell: (p: PassholderForTable) => p[c.name]
        };
      });
    }
    return [];
  }

  getAllDependentData(
    params: ZpxApiPassholderParams
  ): Observable<
    [PassholdersReportHttpResponseBody, CustomTypeColumn[], Passholder, string]
  > {
    const getPassholdersObs$ = this.filterBarService.filterBody$.pipe(
      switchMap((body) => this.zpxApiService.getPassholders(params, body)),
      catchError((error) => {
        this.tableLoading.next(false);
        return throwError(error);
      })
    );
    const getCustColsObs$ = this.zpxApiService.getCustomColumns().pipe(
      catchError((error) => {
        this.tableLoading.next(false);
        return throwError(error);
      })
    );
    return combineLatest([
      getPassholdersObs$,
      getCustColsObs$,
      this.appService.patchedPassholder$,
      this.appService.passholderTypeId$
    ]);
  }

  shouldLoadData(): Observable<boolean> {
    return combineLatest([
      this.divisionsService.isSingleDivisionUser(),
      this.appService.selectedDivisionId$
    ]).pipe(
      map(([isSingleUser, divisionId]) => {
        if (isSingleUser) {
          return true;
        }

        if (divisionId) {
          return true;
        }

        return false;
      })
    );
  }

  getPassholdersForTable(params: ZpxApiPassholderParams) {
    this.tableLoading.next(true);
    this.shouldLoadData()
      .pipe(
        mergeMap((shouldLoadData) => {
          if (shouldLoadData) {
            return this.getAllDependentData(params);
          }
          return of(null);
        })
      )
      .subscribe(
        (results) => {
          if (results === null) {
            // we return early and loading spinner stays on screen until a division selection is made in the case of multi-div user who needs the dialog opened
            // will not happen for single div user and we move on accordingly
            return;
          }

          const [passholders, customCols, patchedPassholder, passholderTypeId] =
            results as [
              PassholdersReportHttpResponseBody,
              CustomTypeColumn[],
              Passholder,
              string
            ];
          this.tableLoading.next(false);

          if (patchedPassholder) {
            this.resetPassholdersAfterPatch(
              passholders.data,
              patchedPassholder,
              passholderTypeId
            );
          }

          this.data = this.getPassData(passholders.data);

          this.customColumnsForTable$.next(
            this.getCustomColumnsForTable(customCols)
          );

          this.tableDataSubject$.next(this.data);

          this.totalResults.next(passholders.total_count);
        },
        (error) => {
          this.errorMessage.next(error);
        }
      );
  }

  connect(collectionViewer: CollectionViewer): Observable<any[]> {
    return this.tableDataSubject$.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.tableLoading.complete();
    this.tableDataSubject$.complete();
  }

  onTableDestroy(): void {
    this.tableDataSubject$.complete();
    this.tableLoading.complete();
  }

  resetPassholdersAfterPatch(
    passholderData: Passholder[],
    patchedPassholder: Passholder,
    passholderTypeId: string
  ) {
    const isPassholderTypeChanged =
      patchedPassholder.passholder_type_id &&
      patchedPassholder.passholder_type_id !== passholderTypeId;

    for (let i = 0; i < passholderData.length; i++) {
      const p = passholderData[i];

      if (p.id === patchedPassholder.id) {
        if (isPassholderTypeChanged) {
          passholderData.splice(i, 1);
        } else {
          passholderData[i] = patchedPassholder;
        }

        break;
      }
    }
  }
}
