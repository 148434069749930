<zui-table
  [title]="title"
  [columns]="columns"
  [dataSource]="dataSource"
  [enableMobileTable]="false"
  [initialPageSize]="initialPageSize"
  [enableColumnPicker]="false"
  (rowClick)="click($event)">
  <p>
    <button
      style="margin-right: 3px"
      class="mat-stroked-button mat-button-base white-button">
      Add
    </button>
    <button
      style="margin-right: 3px"
      class="mat-stroked-button mat-button-base white-button">
      Manage Groups
    </button>
    <button
      style="margin-right: 3px"
      class="mat-stroked-button mat-button-base white-button">
      Deactivate Selected
    </button>
    <button
      style="margin-right: 3px"
      class="mat-stroked-button mat-button-base white-button">
      Import
    </button>
    <button class="mat-stroked-button mat-button-base white-button">
      Export
    </button>
    <mat-icon style="margin-right: 3px" class="mat-accent"
      >help_outline</mat-icon
    >
  </p>
</zui-table>
