import { Injectable } from '@angular/core';
import { HttpService } from '@zonar-ui/core';
import { AuthHeadersService } from '../auth-headers-service/auth-headers.service';
import { GetEnvironmentService } from '../get-environment/get-environment.service';
import {
  filter,
  first,
  map,
  mergeMap,
  switchMap,
  catchError,
  distinctUntilChanged,
  tap
} from 'rxjs/operators';
import { combineLatest, Observable, of, throwError } from 'rxjs';

import { HttpHeaders, HttpParams, HttpClient } from '@angular/common/http';
import {
  ZpxApiPassholderParams,
  PassholdersReportHttpResponseBody,
  PassholderType,
  Group,
  PassholderPatchBody,
  ZpxApiCustomColumnParams,
  CustomTypeColumn,
  ZpxApiGetPassholderCommonColumnsParams,
  PASSHOLDER_COMMON_COLUMNS,
  PASSHOLDER_TYPES,
  ZpxApiGetPassholderCommonColumnsResponse,
  ZpxApiGetCustomTypeColumnValuesResponse
} from '../../models/zpx-api.model';
import { AppService } from '@src/app/app.service';

@Injectable({
  providedIn: 'root'
})
export class ZpxApiService {
  constructor(
    private httpService: HttpService,
    private authHeadersService: AuthHeadersService,
    private getEnvService: GetEnvironmentService,
    private httpClient: HttpClient,
    private appService: AppService
  ) {
    this.getGroups().subscribe();
  }

  url = this.getEnvService.getEnvironmentProperty('apiBase')['url'];
  passholdersReportUrl = `${this.url}/passholders/get-report`;
  passholderTypeUrl = `${this.url}/passholder_types`;
  groupsUrl = `${this.url}/zpx-groups`;
  customColumsUrl = `${this.url}/custom-type-column`;
  private _passholderTypes = null;

  _getPassholderTypeCommonColumnValuesUrl(
    passholderType: PASSHOLDER_TYPES,
    commonColumn: PASSHOLDER_COMMON_COLUMNS
  ) {
    return `${this.url}/passholders/${passholderType}/${commonColumn}`;
  }

  _getCustomColumnValuesUrl(customColumnId: string) {
    return `${this.url}/custom-type-columns/${customColumnId}/values`;
  }

  getCustomColumns(): Observable<CustomTypeColumn[]> {
    const passholderTypesAndDivision$ = combineLatest([
      this.getPassholderTypes().pipe(first()),
      this.appService.selectedDivisionId$.pipe(distinctUntilChanged())
    ]);

    return this.appService.passholderType$.pipe(
      filter((currentPassholderType) => currentPassholderType !== null),
      first(),
      mergeMap((currentPassholderType) => {
        return passholderTypesAndDivision$.pipe(
          mergeMap(([types, divisionId]) => {
            const typeId = types.find(
              (t) => t.name === currentPassholderType
            ).id;

            this.appService.passholderTypeId$.next(typeId);

            const params = {
              passholder_type_id: typeId
            } as ZpxApiCustomColumnParams;
            if (divisionId) {
              params.division_id = divisionId;
            }
            return this._getCustomColumns(params);
          })
        );
      })
    );
  }

  private _getCustomColumns(
    params: ZpxApiCustomColumnParams
  ): Observable<CustomTypeColumn[]> {
    return this.authHeadersService.getAuthHeaders().pipe(
      filter((headers) => headers !== null),
      mergeMap((headers) => {
        return this.httpService
          .get(
            this.customColumsUrl,
            new HttpParams({ fromObject: { ...params } }),
            new HttpHeaders(headers)
          )
          .pipe(
            map((response) => {
              const customColumns = response.body as CustomTypeColumn[];
              this.appService.customColumns$.next(customColumns);
              return customColumns
                .slice()
                .sort((a, b) => a.sequence - b.sequence);
            })
          );
      })
    );
  }

  getPassholders(
    params: ZpxApiPassholderParams,
    body = {}
  ): Observable<PassholdersReportHttpResponseBody> {
    const headersAndDivision = combineLatest([
      this.authHeadersService.getAuthHeaders(),
      this.appService.selectedDivisionId$.pipe(distinctUntilChanged())
    ]);
    // Hacky conversion of params into body
    // TODO - clean this upstream
    body = { ...body, ...params };
    return headersAndDivision.pipe(
      filter(([headers]) => headers !== null),
      mergeMap(([headers, divisionId]) => {
        if (divisionId) {
          params.division_id = divisionId;
        }
        if (headers) {
          return this.httpService
            .post(
              this.passholdersReportUrl,
              body,
              null, // This is hacky, but we dont need the params to be passed in the request
              new HttpHeaders(headers)
            )
            .pipe(
              map((response) => {
                return response.body as PassholdersReportHttpResponseBody;
              })
            );
        }
      })
    );
  }

  getPassholderTypes(): Observable<PassholderType[]> {
    if (this._passholderTypes?.length) {
      return of(this._passholderTypes);
    }
    return this.authHeadersService.getAuthHeaders().pipe(
      filter((headers) => headers !== null),
      mergeMap((headers) => {
        return this.httpService
          .get(this.passholderTypeUrl, null, new HttpHeaders(headers))
          .pipe(
            map((response) => {
              const passholderTypes = response.body as PassholderType[];
              this._passholderTypes = passholderTypes;
              this.appService.passholderTypes$.next(passholderTypes);
              return passholderTypes;
            })
          );
      })
    );
  }

  getPassholderCommonColumnValues(
    commonColumn: PASSHOLDER_COMMON_COLUMNS
  ): Observable<ZpxApiGetPassholderCommonColumnsResponse> {
    const typesAndHeadersAndDivisionId$ = combineLatest([
      this.appService.passholderType$,
      this.authHeadersService.getAuthHeaders(),
      this.appService.selectedDivisionId$
    ]);
    return typesAndHeadersAndDivisionId$.pipe(
      switchMap(([passholderType, headers, divisionId]) => {
        const params: ZpxApiGetPassholderCommonColumnsParams = {};
        if (divisionId) {
          params.division_id = divisionId;
        }
        const url = this._getPassholderTypeCommonColumnValuesUrl(
          passholderType,
          commonColumn
        );
        return this.httpService.get(
          url,
          new HttpParams({ fromObject: { ...params } }),
          new HttpHeaders(headers)
        );
      }),
      map(
        (response) => response.body as ZpxApiGetPassholderCommonColumnsResponse
      )
    );
  }

  getGroups(): Observable<Group[]> {
    const headersAndDivisionId$ = combineLatest([
      this.authHeadersService.getAuthHeaders(),
      this.appService.selectedDivisionId$
    ]);
    return headersAndDivisionId$.pipe(
      filter(([headers]) => headers !== null),
      mergeMap(([headers, divisionId]) => {
        let params = null;
        if (divisionId) {
          params = new HttpParams({ fromObject: { division_id: divisionId } });
        }
        return this.httpService
          .get(this.groupsUrl, params, new HttpHeaders(headers))
          .pipe(
            map((response) => {
              const groups = response.body as Group[];
              this.appService.groups$.next(groups);
              return groups;
            })
          );
      })
    );
  }

  getCustomColumnValues(
    customColumndId: string
  ): Observable<ZpxApiGetCustomTypeColumnValuesResponse> {
    const headersAndDivisionId$ = combineLatest([
      this.authHeadersService.getAuthHeaders(),
      this.appService.selectedDivisionId$
    ]);
    return headersAndDivisionId$.pipe(
      first(),
      switchMap(([headers, divisionId]) => {
        let params = null;
        if (divisionId) {
          params = new HttpParams({ fromObject: { division_id: divisionId } });
        }
        const url = this._getCustomColumnValuesUrl(customColumndId);
        return this.httpService.get(url, params, new HttpHeaders(headers)).pipe(
          map((response) => {
            return response.body as ZpxApiGetCustomTypeColumnValuesResponse;
          })
        );
      })
    );
  }

  patchPassholder(id: string, patchBody: PassholderPatchBody): Observable<any> {
    const url = `${this.url}/passholder/${id}/extended`;
    return this.authHeadersService.getAuthHeaders().pipe(
      filter((headers) => headers !== null),
      mergeMap((headers) => {
        return this.httpClient
          .patch(url, patchBody, {
            headers: new HttpHeaders(headers)
          })
          .pipe(
            catchError((err) => {
              return throwError(err);
            })
          );
      })
    );
  }
}
