<div id="division-picker">
  <mat-dialog-content>
    <h3>Select Division</h3>
    <app-filter-dropdown
      [label]="label"
      [options]="options"
      [isMultiple]="isMultiple"
      [z_formGroup]="formGroup"
      [selectOptionsControlName]="selectOptionsControlName">
    </app-filter-dropdown>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button
      class="mat-stroked-button mat-button-base"
      (click)="onApply()"
      [ngClass]="selectedDivisonId ? 'accent-button' : 'mat-button-disabled'">
      Apply
    </button>
  </mat-dialog-actions>
</div>
