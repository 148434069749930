import { PASSHOLDER_TYPES } from '@src/app/models/zpx-api.model';
import {
  DropdownOptionsObject,
  SearchableDropdownModel
} from '@zonar-ui/filter';

export interface CustomColumnSearchableDropdown
  extends SearchableDropdownModel {
  customColumnId: string;
}

export interface CachedPassholderTypesFiltersModel {
  student?: PassholderTypeFiltersCache;
  driver?: PassholderTypeFiltersCache;
  aide?: PassholderTypeFiltersCache;
}

export interface CommonColumnsFilters {
  dropDownOptions: { [key in COMMON_COLUMN_TYPES]?: DropdownOptionsObject[] };
}

export interface CustomColumnsFilters {
  dropDownOptions: {
    [K: string]: DropdownOptionsObject[];
  };
  filters: CustomColumnSearchableDropdown[];
}

export type TablePassholdersFilterGroupValue = {
  division_id?: string;
  last_name?: string[];
  first_name?: string[];
  exsid?: string[];
  group?: string[];
  pass_number?: string[];
  active?: string[];
} & { [key: `custCol_${string}`]: string[] };

export enum COMMON_COLUMN_TYPES {
  FIRST_NAMES = 'firstNames',
  LAST_NAMES = 'lastNames',
  DRIVER = 'driver',
  UNIQUE_IDS = 'uniqueIds',
  NUMBERS = 'numbers',
  GROUPS = 'groups',
  DIVISIONS = 'divisions'
}

export class PassholderTypeFiltersCache {
  passholderType: PASSHOLDER_TYPES;
  commonColumns: CommonColumnsFilters;
  customColumns: CustomColumnsFilters;
  constructor(passholderType: PASSHOLDER_TYPES) {
    this.passholderType = passholderType;
    this.commonColumns = {
      dropDownOptions: {}
    };
    this.customColumns = {
      filters: null,
      dropDownOptions: {}
    };
  }
  getCommonColumnsOptions() {
    return this.commonColumns.dropDownOptions;
  }
  getCustomColumnOptions() {
    return this.customColumns.dropDownOptions;
  }
  getCustomColumnFilters() {
    return this.customColumns.filters;
  }
  resetCache() {
    this.commonColumns.dropDownOptions = {};
    this.customColumns.filters = [];
    this.customColumns.dropDownOptions = {};
  }
}
